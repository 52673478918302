// @flow
import * as React from 'react'
import { StaticQuery, graphql } from 'gatsby'
// import { StaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
// import Helmet from 'react-helmet'
// import Slick from 'react-slick'
import Analytics from '../utils/analytics'
// import RenderIf from '../components/RenderIf'
// import
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Nav from '../components/Nav'
// import Icon from '../components/Icon'
import PaddedContent from '../components/PaddedContent'
// import Button from '../components/Button'
// import Devices from '../components/Devices'
import { type Fluid } from '../types'
import { Header, DemoItems, DemoLink } from '../styles/pages/demo'

type Props = {
  location: any,
}

type State = {
  videoLightboxIsOpen: boolean,
}

type StaticQueryData = {
  contentfulDemoPage: {|
    pageTitle: string,
    keywords: Array<string>,
    description: {
      description: string,
    },
    demoPageHeadingTitle: string,
    demoPageHeadingSubtitle: string,
    keywords: Array<string>,
    description: {
      description: string,
    },
    headingTitle: string,
    headingSubtitle: string,
    demoItems: Array<{|
      id: string,
      title: string,
      linkLabel: string,
      linkURL: string,
      decription: { decription: string },
      image: {
        title: string,
        ...Fluid,
      },
    |}>,
  |},
}

class DemoPage extends React.Component<Props, State> {
  state = {
    // videoLightboxIsOpen: false,
  }

  componentDidMount() {
    Analytics.pageview()
  }

  render() {
    const { location } = this.props

    return (
      <StaticQuery
        query={demoPageQuery}
        render={(data: StaticQueryData) => {
          const {
            pageTitle,
            keywords,
            description: { description },
            demoPageHeadingTitle,
            demoPageHeadingSubtitle,
            // headingTitle,
            // headingSubtitle,
            demoItems,
          } = data.contentfulDemoPage

          return (
            <Layout dependencies={['slick']}>
              <SEO
                title={pageTitle}
                description={description}
                keywords={keywords}
                location={location}
              />
              <Header>
                <Header.Background />
                <Nav location={location} />
                <PaddedContent>
                  <Header.Title>{demoPageHeadingTitle}</Header.Title>
                  <Header.Subtitle>{demoPageHeadingSubtitle}</Header.Subtitle>
                  <Header.Subtitle>Demo Development Page</Header.Subtitle>
                </PaddedContent>
              </Header>
              <PaddedContent>
                <DemoItems>
                  <DemoItems.Grid>
                    {demoItems.map(demoItem => (
                      <DemoItems.DemoItem key={demoItem.id}>
                        <DemoLink to={demoItem.linkURL}>
                          <DemoItems.DemoItem.Photo>
                            <Img fluid={demoItem.image.fluid} title={demoItem.image.title} />
                          </DemoItems.DemoItem.Photo>
                        </DemoLink>
                        <DemoItems.DemoItem.Name>{demoItem.title}</DemoItems.DemoItem.Name>
                        <DemoItems.DemoItem.Biography>
                          {demoItem.description.description}
                        </DemoItems.DemoItem.Biography>
                      </DemoItems.DemoItem>
                    ))}
                  </DemoItems.Grid>
                </DemoItems>
              </PaddedContent>
            </Layout>
          )
        }}
      />
    )
  }
}

export default DemoPage

const demoPageQuery = graphql`
  query demoPageQuery {
    contentfulDemoPage {
      pageTitle
      keywords
      description {
        description
      }
      demoPageHeadingTitle
      demoPageHeadingSubtitle
      demoItems {
        id
        title
        description {
          description
        }
        linkLabel
        linkURL
        image {
          title
          fluid(maxWidth: 760, quality: 99) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`

// GatsbyContentfulFluid_withWebp
// SEE https://github.com/gatsbyjs/gatsby/issues/20984
