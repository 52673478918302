// @flow
import styled, { StyledFunction } from '@emotion/styled'
import { css } from '@emotion/core'
import OutboundLink from '../../components/OutboundLink'
import { media } from '../dimensions'
import * as Fonts from '../fonts'
import Colors from '../colors'
import { LinkContainer } from '../../components/Button/style'
import Link from '../../components/Link'
// import styled, { StyledFunction } from "styled-components"

export const Header = styled.div`
  position: relative;
  padding-bottom: 16px;

  ${media.tablet`
    padding-bottom: 32px;
  `};
`
const newsItemTransitionDuration = '0.5s'

Header.Background = styled.div`
  background: ${Colors.secondary};
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

Header.Title = styled.h1`
  ${Fonts.Title};
  position: relative;
  margin: 0 0 24px;
  color: ${Colors.white};
  text-align: center;
`

Header.Subtitle = styled.h2`
  ${Fonts.Subtitle};
  text-align: center;
  color: ${Colors.white};
  max-width: 712px;
  margin: 0 auto 32px;
`

Header.Buttons = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;

  ${LinkContainer} {
    margin-bottom: 16px;
  }

  ${media.tablet`
    flex-direction: row;
    justify-content: center;
    
    ${LinkContainer} {
      margin: 0 24px 0 0;

      &:last-of-type {
        margin: 0;
      }
    }
  `};
`

export const DemoItems = styled.div`
  padding: 48px 0;

  ${media.tablet`
    padding: 72px 0;
  `};
`

DemoItems.Title = styled.h2`
  ${Fonts.Title};
  color: ${Colors.grey1};
  text-align: center;
  margin-bottom: 48px;

  ${media.tablet`
    margin-bottom: 72px;
  `};
`

DemoItems.Grid = styled.div`
  display: grid;
  grid-column-gap: 40px;
  grid-row-gap: 56px;

  ${media.tablet`
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 72px;
  `};

  ${media.desktop`
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 72px;
  `};
`

DemoItems.DemoItem = styled.div`
  position: relative;
`

DemoItems.DemoItem.Photo = styled.div`
  cursor: pointer;
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 16px;
  img {
    transition: transform 2s !important;
  }

  &:hover {
    img {
      transform: scale(1.05);
    }
  }
`

DemoItems.DemoItem.Name = styled.h4`
  ${Fonts.Subtitle2};
  margin-bottom: 4px;
`

DemoItems.DemoItem.Title = styled.h5`
  ${Fonts.Body2};
`

DemoItems.DemoItem.Email = styled.p`
  ${Fonts.Body2};

  a {
    ${Fonts.Link};
    color: ${Colors.secondary};
  }
`

DemoItems.DemoItem.Social = styled(OutboundLink)`
  display: block;
  width: 24px;
  height: 24px;
  margin-top: 8px;
  color: ${Colors.grey4};
  opacity: 0.5;
  position: relative;
  overflow: hidden;

  &:hover {
    opacity: 1;
  }

  span {
    position: absolute;
    top: -200px;
  }
`

DemoItems.DemoItem.Biography = styled.p`
  ${Fonts.Body2};
  margin-top: 16px;
`

export const News = styled.div`
  background: ${Colors.offWhite};
  padding: 48px 0;

  ${media.tablet`
    padding: 72px 0;
  `};
`

News.Title = styled.h2`
  ${Fonts.Title};
  color: ${Colors.grey1};
  text-align: center;
  margin-bottom: 48px;

  ${media.tablet`
    margin-bottom: 72px;
  `};
`

News.Items = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: grid;
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  transition: all ${newsItemTransitionDuration};
  grid-template-columns: 1fr;
  grid-auto-flow: dense;

  ${media.tablet`
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: dense;
    /* grid-row-gap: 72px; */
  `};

  ${media.desktopHD`
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-flow: dense;
    /* grid-row-gap: 72px; */
  `};
`

const NewsItemIcon = styled.div`
  display: flex;
  opacity: 0;
  transition: opacity ${newsItemTransitionDuration};
  box-sizing: border-box;
  position: absolute;
  width: 32px;
  height: 32px;
  background: ${Colors.util.fade({ color: Colors.white, amount: 0.8 })};
  border: 2px solid ${Colors.util.fade({ color: Colors.white, amount: 0.7 })};
  border-radius: 16px;
  align-items: center;
  justify-content: center;
  top: 16px;
  right: 16px;
  color: ${Colors.white};
  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;
  }

  &:hover {
    border: 2px solid ${Colors.white};
  }
`

News.Item = styled.li`
  will-change: transform;
  /* text-align: center; */
  border-radius: 6px;
  background: ${Colors.white};
  box-shadow: 1px 2px 3px 0 ${Colors.util.fade({ color: Colors.pureBlack, amount: 0.9 })};
  padding-bottom: 32px;
  cursor: ${props => (props.expanded ? 'default' : 'pointer')};

  ${props => css`
    grid-column: ${props.expanded ? 'span 1' : ''};

    ${media.tablet`
      grid-column: ${props.expanded ? 'span 2' : ''};  
    `};

    ${media.desktopHD`
      grid-column: ${props.expanded ? 'span 3' : ''};
    `};
  `}

  &:hover {
    .gatsby-image-wrapper {
      opacity: ${props => (props.expanded ? 1 : 0.7)};
    }

    p {
      color: ${Colors.grey1};
    }

    ${NewsItemIcon} {
      opacity: 1;
    }
  }
`

News.Item.Photo = styled.div`
  height: 264px;
  border-radius: 6px 6px 0 0;
  overflow: hidden;
  position: relative;
  background: ${Colors.pureBlack};

  /* stylelint-disable */
  .gatsby-image-wrapper {
    /* stylelint-enable */
    height: 100%;
    transition: opacity ${newsItemTransitionDuration};
  }
`

News.Item.Icon = NewsItemIcon

News.Item.Title = styled.h4`
  ${Fonts.Subtitle2};
`

News.Item.Date = styled.p`
  ${Fonts.Caption};
  margin-bottom: 24px;
`

News.Item.Text = styled.p`
  ${Fonts.Caption};
  margin-bottom: 24px;
`

News.Item.PullQuote = styled.div`
  /* padding: 0 24px; */
  display: flex;
  justify-content: center;
`

News.Item.PullQuote.Icon = styled.div`
  width: 40px;
  height: 40px;
  opacity: 0.2;
  margin-right: 16px;
  justify-self: end;
  text-align: right;

  svg {
    width: 40px;
    height: 40px;
    opacity: 0.4;
  }
`

News.Item.PullQuote.Quote = styled.p`
  flex: 1;
  ${Fonts.Caption};
  text-align: left;
  font-style: italic;
  transition: color ${newsItemTransitionDuration};
`

News.Item.MetaCollapsed = styled.div`
  padding: 24px 24px 0;

  ${News.Item.Title} {
    margin-bottom: 0;
  }

  /* stylelint-disable */
  ${News.Item.Date} {
    /* stylelint-enable */
    transition: color ${newsItemTransitionDuration};
  }
`
News.Item.MetaExpanded = styled.div`
  padding: 48px;

  ${News.Item.Title} {
    ${Fonts.Title2};
    margin-bottom: 8px;
  }

  ${News.Item.Date} {
    ${Fonts.Body};
    margin-bottom: 24px;
  }

  ${News.Item.Text} {
    ${Fonts.Body};
    margin-bottom: 24px;
  }

  ${News.Item.PullQuote} {
    max-width: 400px;
    position: relative;
    /* left: -24px; */
  }
`

News.Item.Images = styled.div`
  padding: 0 32px;

  .slick-next::before,
  .slick-prev::before {
    color: ${Colors.primary};
  }

  * {
    outline: none;
  }
`

News.Item.Image = styled.div`
  /* height: 600px; */
  /* background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(${props => props.url}); */
  
  img {
    width: 100%;
    height: auto;
    margin: 0 auto;
  }
  
`

export const activeLinkStyle = {
  background: Colors.util.fade({ color: Colors.white, amount: 0.9 }),
}

export const DemoLink = styled(Link)`
  // text-decoration: none;
  // color: ${Colors.white};
  // display: block;
  // border-radius: 6px;
  // padding: 8px 8px;
  // margin-bottom: 8px;
  // transition: background 1s;

  // &:hover {
  //   ${css`
  //     ${activeLinkStyle}
  //   `};
  //   transition: none;
  // }

  // ${media.tablet`
  //   display: inline-block;
  //   margin: 0 0 0 16px;
  // `};
`
export const TabletFrame = styled.div`
  background-repeat: no-repeat;
  background-position: center center;
  // backgroundimage: url('https:'+{props=>props.url});
  background-size: contain;
  display: block;
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  object-fit: contain;
`
const outerWrapper: StyledFunction<{ ratio: number }> = styled.div
export const OuterWrapper = outerWrapper`
  position: relative;
  width: 100%;
  height: 0;
  /**
   * For human readability, the ratio is expressed as
   * width / height, so we need to invert it.
   */
  padding-bottom: ${props => (1 / props.ratio) * 100}%;
`
export const InnerWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`
