// @flow

import styled from '@emotion/styled'
import { css } from '@emotion/core'
import Link from '../Link'
import Colors from '../../styles/colors'
import * as Fonts from '../../styles/fonts'

export const BUTTON_KINDS = Object.freeze({
  PRIMARY: 'primary',
  OUTLINE_ON_DARK: 'outline-on-dark',
})

export type ButtonKindsT = $Values<typeof BUTTON_KINDS>

const getStyleFromProps = ({ kind, disabled }: { kind: ButtonKindsT, disabled: boolean }) => {
  switch (kind) {
    case 'outline-on-dark':
      return css`
        border: 1px solid ${Colors.util.fade({ color: Colors.white, amount: 0.6 })};
        padding: 12px 24px;

        &:hover {
          transition: none;
          background: ${disabled
            ? 'transparent'
            : Colors.util.fade({ color: Colors.white, amount: 0.9 })};
        }

        &:active {
          background: ${disabled
            ? 'transparent'
            : Colors.util.fade({ color: Colors.white, amount: 0.8 })};
        }
      `

    default:
      return css`
        background: ${disabled ? Colors.util.disabled(Colors.primary) : Colors.primary};
        padding: 20px 28px;

        &:hover {
          transition: none;
          background: ${disabled
            ? Colors.util.disabled(Colors.primary)
            : Colors.util.hover(Colors.primary)};
        }

        &:active {
          background: ${disabled
            ? Colors.util.disabled(Colors.primary)
            : Colors.util.pressed(Colors.primary)};
        }
      `
  }
}

export const ButtonContainer = styled.button`
  ${Fonts.Button};
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  ${props => getStyleFromProps(props)};
  transition: background 1s;
  border-radius: 6px;
  text-decoration: none;
  position: relative;
  display: flex;
  align-items: center;
`

export const LinkContainer = ButtonContainer.withComponent(Link)

export const Label = styled.span`
  opacity: ${props => (props.visible ? 1 : 0)};
  text-align: center;
  width: 100%;
  display: block;
  flex: 1;
`

export const SpinnerContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`
